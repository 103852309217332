<template>
  <div class="app-containers">
    <div style="float:left;margin-bottom:10px">
      <search @success="getList">
        <el-form style="margin:11px 0px 0px -26px;width:430px" label-width="120px" class="styleForm">
          <el-form-item label="存货名称 ：">          
            <el-input placeholder="请输入名称或编号" style="width: 200px;" size="small" v-model="listQuery.name"/>
          </el-form-item>
          <el-form-item label="账期 ：">   
            <qzf-period v-model:period="listQuery.period"></qzf-period>
          </el-form-item>
          <el-form-item label="冲销状态 ：">   
            <el-select v-model="listQuery.status" placeholder="冲销状态" size="small">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="已冲销" :value="1"></el-option>
              <el-option label="未冲销" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </search>
    </div>
    <div style="text-align:right;float:right">
      <qzf-button size="small" type="primary" @success="writeOff"   >
        <el-icon><List /></el-icon><span  > 批量冲销</span>
      </qzf-button>
      <qzf-button @success="inventoryStockEsti" size="small" >
        <el-icon><SortDown /></el-icon><span  > 暂估入库</span>
      </qzf-button>
      <settings name="暂估科目设置" :type="['code_inventory_zg']"></settings>
    </div>

    <el-table stripe :data="list" style="width: 100%" @selection-change="handleSelectionChange" size="small" border :height="contentStyleObj">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="selection" min-width="55"></el-table-column>
      <el-table-column align="center" label="生成日期" min-width="120">
        <template #default="scope">
          <span>{{ $parseTime(scope.row.createdAt, "{y}-{m}-{d}")}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="暂估日期" min-width="120">
        <template #default="scope">
          <span>{{ $parseTime(scope.row.time, "{y}-{m}-{d}")}}</span> 
        </template>
      </el-table-column>
      <el-table-column align="center" label="暂估账期" min-width="120">
        <template #default="scope">
          <span>{{ scope.row.period }}</span> 
        </template>
      </el-table-column>
      <el-table-column align="center" label="类别" min-width="120">
        <template #default="scope">
          <span>{{$inventoryTypeFilter(scope.row.inventoryType)}}</span> 
        </template>
      </el-table-column>
      <el-table-column label="名称" min-width="120">
        <template #default="scope">
          <span>{{ scope.row.name }}</span> 
        </template>
      </el-table-column>
      <el-table-column align="center" label="型号" min-width="120">
        <template #default="scope">
          <span>{{ scope.row.spec }}</span> 
        </template>
      </el-table-column>
      <el-table-column align="center" label="单位" min-width="50">
        <template #default="scope">
          <span>{{ scope.row.unit }}</span> 
        </template>
      </el-table-column>
      <el-table-column align="center" label="数量" min-width="50">
        <template #default="scope">
          <span>{{ scope.row.count }}</span> 
        </template>
      </el-table-column>
      <el-table-column align="center" label="单价" min-width="120">
        <template #default="scope">
          <span>{{ (scope.row.amount *1/scope.row.count*1).toFixed(2) }}</span> 
        </template>
      </el-table-column>
      <el-table-column align="center" label="金额" min-width="120">
        <template #default="scope">
          <span>{{ scope.row.amount }}</span> 
        </template>
      </el-table-column>
      <el-table-column align="center" label="已冲销数量" min-width="120">
        <template #default="scope">
          <span>{{ scope.row.backCount }}</span> 
        </template>
      </el-table-column>
      <el-table-column align="center" label="已冲销金额" min-width="120">
        <template #default="scope">
          <span>{{ scope.row.backAmount }}</span> 
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="120">
        <template #default="scope">
          <qzf-button v-if="scope.row.backwashStatus == 0 && periodSelected > scope.row.period" size="small" type='text' @success="handleChongXiao(scope.row)">冲销</qzf-button>
          <span v-if="scope.row.backwashStatus != 0">已冲销</span>
        </template>
      </el-table-column>
    </el-table>

    <inventoryWriteOff ref="inventoryWriteOff" @success="getList()"></inventoryWriteOff>
    <inventoryStock ref="inventoryStockEsti" @success="getList()" name="暂估入库"></inventoryStock>
  </div>
</template>

<script>
import { estimateList } from '@/api/inventory'
import inventoryStock from "./inventoryStock.vue"
import inventoryWriteOff from "./inventoryWriteOff.vue"
import Search from '../../../../components/search/search.vue'
export default {
  name:'inventoryEstimated',
  components:{
    inventoryStock,
    inventoryWriteOff,
    Search
  },
  data() {
    return {
      list:[],
      listQuery: {
        name: "",
        period: "",
        status: 0,
      },
      periodSelected: "",
      select:[],
      periodList:[]
    }
  },
  created() {
    this.getList()
    this.periodSelected = this.$store.getters['user/comInfo'].period
    this.contentStyleObj= this.$getHeight(380)
  },
  methods:{
    writeOff(){
      // 批量冲销 考虑账期 todo
      let array = []
      let statusCx = false
      let statusCx2 = false
      this.select.map(item => {
        if(this.periodSelected <= item.period){
          statusCx2 = true
        }
        if(item.backwashStatus != 0){
          statusCx = true
        }
        array.push({
          id:item.id*1,
          name: item.name,
          backAmount: (item.amount * 1 - item.backAmount * 1).toFixed(2) * 1,
          backCount: (item.count * 1 - item.backCount * 1).toFixed(3) * 1,
        });
      });
      if(statusCx){
        this.$qzfMessage("不可重复冲销",2)
        return
      }
      if(statusCx2){
        this.$message.error('本月不可冲销本月及本月之后暂估的');
        return
      }
      if(array.length == 0){
        this.$message.error('请至少选择一条')
        return
      }
      this.$refs.inventoryWriteOff.getList(array)
    },
    getList() {
      estimateList(this.listQuery).then(res=>{
        if(res.data.data.list){
          this.list = res.data.data.list
        }else{
          this.list = []
        }
      })
    },
    handleChongXiao(item){
      let tempItem = {
        id: item.id,
        name:item.name,
        backAmount: (item.amount * 1 - item.backAmount * 1).toFixed(2) * 1,
        backCount: (item.count * 1 - item.backCount * 1).toFixed(3) * 1,
      }
      this.$refs.inventoryWriteOff.getList([tempItem])
    },
    // 多选 批量冲销
    handleSelectionChange(val){
      this.select = val
    },
    //暂估入库
    inventoryStockEsti(){
      this.$refs.inventoryStockEsti.init()
    },
  }
}
</script>

<style scoped lang="scss">
 
.el-select{
  margin-left:0px
}
.app-containers {
  padding: 10px 20px;
  width: 100%;
  margin: 0 auto 0px auto;
  min-height: calc(100vh - 270px);
  border:1px solid #e6e6e6;
  position: relative;
}
</style>
<template>
  <span>
    <qzf-button type="text" size="small" @success="conversionClick()">单位换算</qzf-button>

    <!-- 新增弹窗单位换算 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close append-to-body width="30%"  v-model="dialogConversion" title="新增单位换算"  class="dwhsDialog">
      <el-form ref="dataForm" label-position="left" label-width="60px" class="clearfix" style="overflow:hidden">
        <!-- 新增单位换算 -->
        <qzf-button style="margin-bottom:10px;" size="small" @click="addUnits" round>新增换算单位</qzf-button>
        <div style="margin-bottom:10px;">
          <span>示例：单位：</span>
          <el-input disabled style="width:100px" v-model="this.tun" placeholder="" size="small"></el-input>
          <span>-----</span>
          <el-input disabled style="width:100px" v-model="this.kg" placeholder="" size="small"></el-input>
          <span>吨</span>

          <span>=</span>
          <span>1千克</span>  
        </div>
        <div style="margin-bottom:10px;" v-for="(item,index) in units" :key="index">
          <span>单位：</span>
          <el-input style="width:100px" v-model="item.name" placeholder="" size="small"></el-input>
          <span>-----</span>
          <el-input style="width:100px" v-model="item.conversion" placeholder="" size="small"></el-input>
          <span>{{item.name}}</span>
          <span>=</span>
          <span>1{{unitinfo.unit}}</span>  
          <i style="font-size:20px;margin-left:10px;cursor: pointer;" @click="delUnits(index)" class="iconfont icon-shanchu"></i>       
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <qzf-button @success="dialogConversion = false" size="small">取 消</qzf-button>
          <qzf-button type="primary" @success="conversionSave()" size="small">确 定</qzf-button>
        </span>
      </template>
    </el-dialog>
  </span>
</template>
<script>
import {
  inventoryUnits,
  inventoryUnitSave,
} from "@/api/inventory";
export default {
  props: {
    row: {},
  },
  components: { 

  },
  name: "inventoryManage",
  data() {
    return {
      dialogConversion: false,
      units: [],
      tun:'吨',
      kg:0.001
    }
  },
  created() {
    
  },
  computed: {
    
  },
  methods: {
    conversionSave(){
      if(this.units.length == 0){
        let param = {
          inventoryId: this.inventoryUnitId,
          items: []
        }
        inventoryUnitSave(param).then(res=>{
          this.$message({
            message: '更新成功',
            type: 'success'
          });
        })
        this.dialogConversion = false
      }else{
        this.units.map(v=>{
          if(Number(v.conversion)){
            let param = {
              inventoryId: this.inventoryUnitId,
              items: this.units
            }
            inventoryUnitSave(param).then(res=>{
              this.$message({
                message: '更新成功',
                type: 'success'
              });
            })
            this.dialogConversion = false
          }else{
            this.$message({
              message: '请输入正确的单位',
              type: 'warning'
            });
          }
        })
      }
    },
    delUnits(index){
      this.units.splice(index, 1)
    },
    addUnits(){
      this.units.push({
        inventoryId: this.unitinfo.id,
        name: "",
        conversion: "",
      })
    },
    conversionClick(){
      console.log(this.row);
      this.inventoryUnitId = this.row.id
      inventoryUnits({'inventoryId': this.row.id}).then(res=>{
        this.units = res.data.data.list
        this.unitinfo = res.data.data.info
        this.dialogConversion = true
      })
    },
  }
};
</script>
<style>

</style>
<style lang="scss" scoped>

.top_right_btns {
  i {
    margin: 0 5px;
    cursor: pointer;
    color: #f15a24;
    font-size: 18px;
    line-height: 24px;
  }
}
.top_btns{
  .left_search {
    float: left;
    width: 66%;
  }
  .right_btns {
    float: right;
    width: 34%;
    text-align: right;
  }
}
.dialog_box{
  .item{
    display: inline-block;
    width: calc(100% / 3 - 6px);
  }
}
.dwhsDialog{
  .el-dialog{
    text-align: left;
  }
}


</style>

<template>
  <div class="app-containers">
    <div style="width:26%;float:left;margin-bottom:10px">
      <search @success="getList">
        <el-form style="margin:11px 0px 0px -26px;width:430px" label-width="120px" class="styleForm">
          <el-form-item label="存货名称 ：">          
            <el-input v-model="listQuery.name" placeholder="请输入名称" style="width: 200px;" size="small" />
          </el-form-item>
          <el-form-item label="存货类型 ："> 
            <selectInventoryType v-model:type="listQuery.type"></selectInventoryType>
          </el-form-item>
          <el-form-item label="存货为0 ："> 
            <el-radio v-model="listQuery.not0" :label="true">不显示</el-radio>
            <el-radio v-model="listQuery.not0" :label="false">显示</el-radio>
          </el-form-item>
          
        </el-form>
      </search>
    </div>
    <div style="width:40%;text-align:right;float:right">
      <qzf-button jz="true" @success="inventoryStockIn" size="small">
        <el-icon><SortDown /></el-icon><span  > 手动入库</span>
      </qzf-button>
      <qzf-button jz="true" @success="inventoryStockOut" size="small">
        <el-icon><SortUp /></el-icon> <span  > 手动出库</span>
      </qzf-button>
      <el-dropdown size="small" style="margin-left:10px">
        <el-button size="small" type="primary">
          更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="b">
              <span @click="exportInventory">导出</span>
            </el-dropdown-item>
            <el-dropdown-item command="c">
              <span @click="singleDayin">打印</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <el-table stripe border :data="list" style="width: 100%" show-summary :summary-method="getSummaries" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="id" default-expand-all :height="contentStyleObj" v-loading="loading" @selection-change="handleSelectionChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="selection" width="60"> </el-table-column>

      <el-table-column fixed prop="inventoryName" label="别名" min-width="200">
        <template #default="scope">
          <span style="word-wrap: break-word">{{scope.row.inventoryName2}}</span>
        </template>
      </el-table-column>
      <el-table-column fixed prop="inventoryName" label="名称" min-width="180">
        <template #default="scope">
          <span style="word-wrap: break-word">{{scope.row.inventoryName}}</span>
        </template>
      </el-table-column>

      <el-table-column fixed label="型号" min-width="180">
        <template #default="scope">
          <span>{{scope.row.inventorySpec}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="单位" min-width="150">
        <template #default="scope">
          <span>{{scope.row.inventoryUnit}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="存货类型" min-width="150">
        <template #default="scope">
          <span>{{$inventoryTypeFilter(scope.row.inventoryType)}}</span>
        </template>
      </el-table-column>

      <el-table-column label="期末库存" header-align='center'>
        <el-table-column prop="endCount" label="数量" align="center" min-width="120"></el-table-column>
        <el-table-column label="平均单价" align="center" min-width="120">
          <template #default="scope">
            <span v-if="scope.row.endCount">{{ (scope.row.endAmount/scope.row.endCount).toFixed(2) }}</span>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column prop="endAmount" label="金额" align="center" min-width="120">
        </el-table-column>
      </el-table-column>

      <el-table-column label="本期入库" header-align='center'>
        <el-table-column prop="inCount" label="数量" align="center" min-width="120">
        </el-table-column>
        <el-table-column label="平均单价" align="center" min-width="120">
          <template #default="scope">
            <span v-if="scope.row.inCount">{{ (scope.row.inAmount/scope.row.inCount).toFixed(2) }}</span>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column prop="inAmount" label="金额" align="center" min-width="120">
        </el-table-column>
      </el-table-column>
      <el-table-column label="暂估入库" header-align='center'>
        <el-table-column prop="estimateCount" label="数量" align="center" min-width="120">
        </el-table-column>
        <el-table-column label="平均单价" align="center" min-width="120">
          <template #default="scope">
            <span v-if="scope.row.estimateCount ">{{ (scope.row.estimateAmount/scope.row.estimateCount).toFixed(2) }}</span>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column prop="estimateAmount" label="金额" align="center" min-width="120">
        </el-table-column>
      </el-table-column>
      <el-table-column label="本期出库" header-align='center'>
        <el-table-column prop="outCount" label="数量" align="center" min-width="120">
        </el-table-column>
        <el-table-column label="平均单价" align="center" min-width="120">
          <template #default="scope">
            <span v-if="scope.row.outCount">{{ (scope.row.outAmount/scope.row.outCount).toFixed(2) }}</span>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column prop="outAmount" label="金额" align="center" min-width="120">
        </el-table-column>
      </el-table-column>

      <el-table-column label="期初库存" header-align='center'>
        <el-table-column prop="beginCount" label="数量" align="center" min-width="120">
        </el-table-column>
        <el-table-column label="平均单价"  align="center" min-width="120">
          <template #default="scope">
            <span v-if="scope.row.beginCount ">{{ (scope.row.beginAmount/scope.row.beginCount).toFixed(2) }}</span>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column prop="beginAmount" label="金额" align="center" min-width="120">
        </el-table-column>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
    <inventoryStock @success="getList()" ref="inventoryStockIn" name="手动入库"></inventoryStock>
    <inventoryStock @success="getList()" ref="inventoryStockOut" name="手动出库"></inventoryStock>

    <printVouch ref="printVouch" name="inventoryPeriod"  :listQuery1="this.listQuery"></printVouch>

  </div>
</template>

<script>
import { inventoryPeriodList } from '@/api/inventory'
import { inventorySummarySheet } from "@/api/export"
import inventoryStock from "./inventoryStock.vue"
import selectInventoryType from "./selectInventoryType.vue"

import printVouch from "../../../bookkeeps/components/printVouch.vue"
export default {
  name:'inventoryPeriod',
  components:{
    inventoryStock,
    selectInventoryType,
    printVouch
  },
  data() {
    return {
      list:[],
      sumList:[],
      listQuery: {
        subjectCode: '',
        name: '',
        change: 0,
        page: 1,
        limit: 20,
        comId:this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        not0:false
      },
      total:0,
      loading:false,
      comId: this.$store.getters['user/comInfo'].comId*1,
      ids:[]

    }
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(390)
  },
  methods:{
    getList(){
        this.loading = true
      inventoryPeriodList(this.listQuery).then(res=>{
        this.loading = false
        if(res.data.data.list){
          this.list = res.data.data.list
          this.sumList = res.data.data.sum
          this.total = res.data.data.total
        }else{
          this.list = []
          this.sumList = []
          this.total = 0
        }
        
      })
    },
    inventoryStockIn(){
      this.$refs.inventoryStockIn.init()
    },
    inventoryStockOut(){
      this.$refs.inventoryStockOut.init()
    },
    getSummaries(param){
      let { columns, data } = param;
      data = this.sumList
      let endCount = 0
      let endAmount = 0

      let inCount = 0
      let inAmount = 0

      let estimateCount = 0
      let estimateAmount = 0

      let outCount = 0
      let outAmount = 0

      let beginCount = 0
      let beginAmount = 0

      
      endCount = data.endCount
      endAmount = data.endAmount

      inCount = data.inCount
      inAmount = data.inAmount

      estimateCount = data.estimateCount
      estimateAmount = data.estimateAmount

      outCount = data.outCount
      outAmount = data.outAmount

      beginCount = data.beginCount
      beginAmount = data.beginAmount

      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }if(column.property == "endCount"){
          sums[index] = endCount
        }else if(column.property == "endAmount"){
          sums[index] = endAmount
        }else if(column.property == "inCount"){
          sums[index] = inCount
        }else if(column.property == "inAmount"){
          sums[index] = inAmount
        }else if(column.property == "estimateCount"){
          sums[index] = estimateCount
        }else if(column.property == "estimateAmount"){
          sums[index] = estimateAmount
        }else if(column.property == "outCount"){
          sums[index] = outCount
        }else if(column.property == "outAmount"){
          sums[index] = outAmount
        }else if(column.property == "beginCount"){
          sums[index] = beginCount
        }else if(column.property == "beginAmount"){
          sums[index] = beginAmount
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    handleSelectionChange(row) {
      this.ids = []
      row.map(item=>{
        this.ids.push( item.inventoryId )
      })
    },
    singleDayin(){
      this.$refs.printVouch.init([this.comId],this.listQuery.period,this.ids)
    },
    exportInventory(){
      let param = {
        bookNames:['inventoryPeriod'],
        type:'pdf',
        query:this.listQuery
      }
      inventorySummarySheet(param).then(res => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
        }
      })
    }
  }
}
</script>

<style>

</style>

<style scoped lang="scss">
.el-select{
  margin-left:0px
}
.app-containers {
  padding: 10px 20px;
  width: 100%;
  margin: 0 auto 0px auto;
  min-height: calc(100vh - 270px);
  border:1px solid #e6e6e6;
  position: relative;
}


</style>

<style>
/* ::-webkit-scrollbar {
    width: 1px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
    background: rgba(17, 16, 16, 0.13);
    -webkit-box-shadow: rgba(0, 0, 0, 0.9);
    box-shadow: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: #efefef;
} */

</style>
<template>
  <qzf-tabs v-model:value="value" @tab-click="handleClick">
    <qzf-tabs-item label="存货管理" name="1" :cache="false">
      <inventoryCheck ref="inventoryCheck"></inventoryCheck>
      
      <el-tabs type="border-card">
        <el-tab-pane label="库存汇总表">
          <inventoryPeriod ref="inventoryPeriod"></inventoryPeriod>
        </el-tab-pane>
        <el-tab-pane label="出入库明细">
          <inventoryDetail ref="inventoryDetail"></inventoryDetail>
        </el-tab-pane>
        <el-tab-pane label="暂估入库明细表">
          <inventoryEstimated ref="inventoryEstimated"></inventoryEstimated>
        </el-tab-pane>
        <el-tab-pane label="存货设置">
          <inventoryManage ref="inventoryManage"></inventoryManage>
        </el-tab-pane>
        <el-tab-pane label="存货初始化">
          <inventoryInit ref="inventoryInit"></inventoryInit>
        </el-tab-pane>
      </el-tabs>
    </qzf-tabs-item>
    <!-- <qzf-tabs-item label="存货核算" name="5" :cache="false">
      <inventoryCheck ref="inventoryCheck"></inventoryCheck>
    </qzf-tabs-item> -->
    <qzf-tabs-item label="进销存明细" name="6" :cache="false">
      <saleStock ref="inventoryCheck"></saleStock>
    </qzf-tabs-item>
  </qzf-tabs>
</template>

<script>
import inventoryDetail from "./components/inventoryDetail.vue"
import inventoryPeriod from "./components/inventoryPeriod.vue"
import inventoryManage from "./components/inventoryManage.vue"
import inventoryInit from "./components/inventoryInit.vue"
import inventoryEstimated from "./components/inventoryEstimated.vue"
import inventoryCheck from "./components/inventoryCheck.vue"
import saleStock from "./components/saleStock"
export default {
  name: "inventory",
  components:{
    inventoryDetail,
    inventoryPeriod,
    inventoryManage,
    inventoryEstimated,
    inventoryCheck,
    inventoryInit,
    saleStock
  },
  data () {
    return {
      value:'1'
    }
  },
  deactivated(){
    if(!this.$getCachedViews("inventory")){//销毁 mitt bus
      this.$bus.off("inventoryPeriodUpdate")
      this.$bus.off("inventoryEstimatedUpdate")
      this.$bus.off("inventoryManageUpdate")
    }
  },
  created(){
    this.initBus()
  },
  methods:{
    initBus(){
      this.$bus.on("inventoryPeriodUpdate", (val) => {
        if(this.$refs.inventoryPeriod){
          this.$refs.inventoryPeriod.getList()
        }
      });
      this.$bus.on("inventoryEstimatedUpdate", (val) => {
        if(this.$refs.inventoryEstimated){
          this.$refs.inventoryEstimated.getList()
        }
      });
      this.$bus.on("inventoryManageUpdate", (val) => {
        if(this.$refs.inventoryManage){
          this.$refs.inventoryManage.getList()
        }
      });
    }
  }
}
</script>

<style lang="scss">
.addForm{
  .el-select{
    margin-left: 0px;
    width: 100%;
  }
  .el-input{
    width: 72%;
  }
  
}

.inventory .el-tabs__content {
  min-height: 800px;
}
</style>
<template>
  <div class="app-containers">
    <div style="float:left;margin-bottom:10px">
      <search @success="getList">
        <el-form style="margin:11px 0px 0px -26px;width:450px" label-width="120px" class="styleForm">
          <el-form-item label="存货名称 ：">          
            <el-input v-model="listQuery.name" placeholder="请输入名称或编号" style="width: 200px;" size="small" />
          </el-form-item>
          <el-form-item label="存货类型 ：">
            <selectInventoryType v-model:type="listQuery.type"></selectInventoryType>
          </el-form-item>
          <el-form-item label="出入库类型 ："> 
            <el-select v-model="listQuery.detailType" placeholder="请选择来源" size="small">
              <el-option v-for="item in fromOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择账期 ：" :label-width="formLabelWidth">
            <qzf-period v-model:period="listQuery.beginTime" style="width:140px"></qzf-period>
            <span>-</span>
            <qzf-period v-model:period="listQuery.endTime" style="width:140px"></qzf-period>
          </el-form-item>
        </el-form>
      </search>
    </div>
    <div style="width:20%;text-align:right;float:right">
      <el-dropdown size="small" style="margin-left:10px">
        <el-button size="small" type="primary">
          更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="b">
              <span @click="exportInventory">导出</span>
            </el-dropdown-item>
            <el-dropdown-item command="c">
              <span @click="printDetail">打印</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <el-table stripe :data="list" style="width: 100%" border size="small" :height="contentStyleObj" @selection-change="handleSelectionChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="selection" width="50"> </el-table-column>
      <!-- <el-table-column align="center" type="index" width="50" label="序号"> </el-table-column> -->
      <el-table-column align="center" prop="id" label="编号" min-width="70"> </el-table-column>
      <el-table-column prop="inventoryName" label="名称" min-width="180"> </el-table-column>
      <el-table-column prop="inventoryName2" label="别名" min-width="180"> </el-table-column>
      <el-table-column align="center" prop="spec" label="型号" min-width="100"> </el-table-column>
      <el-table-column align="center" prop="time" label="日期" min-width="100">
        <template #default="scope">
          <span>{{$parseTime(scope.row.time, "{y}-{m}-{d}")}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="type" label="出入库类型" min-width="120">
        <template #default="scope">
          <span>{{$inventoryCategoryFilter(scope.row.type)}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="inventoryType" label="存货类型" min-width="180">
        <template #default="scope">
          <span>{{$inventoryTypeFilter(scope.row.inventoryType)}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="unit" label="单位" min-width="80"> </el-table-column>
      <el-table-column align="center" prop="count" label="数量" min-width="130"> </el-table-column>
      <el-table-column align="center" prop="amount" label="金额" min-width="150"> </el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <qzf-button v-if="scope.row.type == 11 || scope.row.type == 10 || scope.row.type == 1" @success="delInventory(scope.row)">撤销</qzf-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>

    <printVouch ref="printVouch" name="inventoryDetail"  :listQuery1="this.listQuery"></printVouch>

  </div>
</template>

<script>
import { inventoryDetailList , delInventoryDetail} from '@/api/inventory'
import selectInventoryType from "./selectInventoryType.vue"
import printVouch from "../../../bookkeeps/components/printVouch.vue"
import { inOutInventorySummarySheet } from "@/api/export"


export default {
  name:'inventoryDetail',
  components:{
    selectInventoryType,
    printVouch
  },
  data() {
    return {
      listQuery:{
        limit:20,
        page:1,
        comId:this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
      },
      total:0,
      list:[],
      fromOptions:[
        {
          value:0,
          label:'全部'
        },
        {
          value:1,
          label:'初始化导入'
        },
        {
          value:2,
          label:'进项入库-进项发票录入'
        },
        {
          value:3,
          label:'暂估入库'
        },
        {
          value:4,
          label:'生产商品入库(生产结转)'
        },
        {
          value:6,
          label:'领料出库(生产结转)'
        },
        {
          value:7,
          label:'商品结转出库(贸易结转)'
        },
        {
          value:8,
          label:'抵消销项卖出(特殊)'
        },
        {
          value:9,
          label:'销项卖出-销项发票录入'
        },
        {
          value:10,
          label:'手工出库'
        },
        {
          value:11,
          label:'手工入库'
        },
        {
          value:12,
          label:'凭证关联出库'
        },
        {
          value:13,
          label:'凭证关联入库'
        },
        {
          value:31,
          label:'暂估回冲'
        },
        {
          value:91,
          label:'销项回冲'
        }
      ],
      comId: this.$store.getters['user/comInfo'].comId*1,
      ids:[]

    }
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(380)
  },
  methods:{
    getList() {
      inventoryDetailList(this.listQuery).then(res=>{
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    delInventory(row){
      delInventoryDetail([row]).then(res=>{
        if(res.data.msg == "success"){
          this.getList()
        }
      })
    },
    handleSelectionChange(row) {
      this.ids = []
      row.map(item=>{
        this.ids.push( item.id )
      })
    },
    printDetail(){
      this.$refs.printVouch.init([this.comId],this.listQuery.period,this.ids)
    },
    exportInventory(){
      let param = {
        bookNames:['inventoryPeriod'],
        type:'pdf',
        query:this.listQuery,
        ids:this.ids
      }
      inOutInventorySummarySheet(param).then(res => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
 
.app-containers {
  padding: 10px 20px;
  width: 100%;
  margin: 0 auto 0px auto;
  min-height: calc(100vh - 270px);
  border:1px solid #e6e6e6;
  position: relative;
}
</style>
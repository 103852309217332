<template>
  <div class="app-containers">
    <div style="float:left;margin-bottom:10px">
      <search @success="getList">
        <el-form style="margin:11px 0px 0px -26px;width:430px" label-width="120px" class="styleForm">
          <el-form-item label="存货名称 ：">          
            <el-input v-model="listQuery.name" placeholder="请输入名称" style="width: 200px;" size="small" />
          </el-form-item>
          <el-form-item label="存货类型 ：">
            <selectInventoryType v-model:type="listQuery.type"></selectInventoryType>
          </el-form-item>
        </el-form>
      </search>
    </div>
    <div style="text-align:right;float:right">
      <qzf-button size="small" type="success" @success="add"   >
        <el-icon><CirclePlus /></el-icon><span  >新增</span>
      </qzf-button>
      <qzf-button size="small" type="danger" @success="delAll"   >
        <el-icon><Delete /></el-icon> <span  >批量删除</span>
      </qzf-button>
      <settings name="设置" :type="['code_inventory_not_use_spec']"></settings>
    </div>
    <div class="app-containter">
      <el-table stripe :data="list" style="width: 100%" border size="small" :height="contentStyleObj" v-loading="loading" @selection-change="handleSelectionChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="名称" min-width="160"> </el-table-column>
        <el-table-column prop="name2" label="别名" min-width="180"> </el-table-column>
        <el-table-column align="center" prop="unit" label="单位" min-width="80"> </el-table-column>
        <el-table-column align="center" prop="spec" label="型号" min-width="80"> </el-table-column>
        <el-table-column align="center" prop="type" label="类型" min-width="180">
          <template #default="scope">
            <span>{{ $inventoryTypeFilter(scope.row.type) }}</span>  
          </template>
        </el-table-column>
        <el-table-column align="center" prop="subjectName" label="科目" min-width="240"> </el-table-column>
        <!-- <el-table-column align="center" prop="count" label="期初数量" min-width="80"> </el-table-column>
        <el-table-column align="center" prop="address" label="期初单价" min-width="80"> </el-table-column>
        <el-table-column align="center" prop="amount" label="期初金额" min-width="80"> </el-table-column> -->
        <!-- <el-table-column align="center" prop="status" label="状态" min-width="180">
          <template #default="scope">
            <el-switch active-text="启用" inactive-text="未启用" @change="changeStatus(scope.row)" :active-value='1' :inactive-value='0' v-model="scope.row.status"></el-switch>
          </template>
        </el-table-column> -->
        <el-table-column align="center" label="操作" min-width="200">
          <template #default="scope">
            <dwhs :row="scope.row"></dwhs>
            <qzf-button size="small" type="text" @success="deleteInventory(scope.row.id)">删除</qzf-button>
            <qzf-button size="small" type="text" @success="create(scope.row)">编辑</qzf-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>
    <add-inventory ref="addInventory" @success="getList"></add-inventory>
  </div>
</template>

<script>
import { inventoryList , delInventory } from '@/api/inventory'
import Search from '../../../../components/search/search.vue'
import selectInventoryType from "./selectInventoryType.vue"
import dwhs from "./dwhs.vue"
export default {
  name:'inventoryManage',
  components:{
    dwhs,
    Search,
    selectInventoryType
  },
  data() {
    return {
      list:[],
      listQuery:{
        name:'',
        type:undefined,
        page:1,
        limit:20
      },
      total:0,
      loading:false,
      // ids:undefined,
      selects:[],
    }
  },
  created() {
    this.getList()
    this.contentStyleObj= this.$getHeight(380)
  },
  methods:{
    getList(){
      this.loading = true
      inventoryList(this.listQuery).then(res=>{
        this.loading = false
        if(res.data.data.list){
          this.list = res.data.data.list
          this.total = res.data.data.total
        }else{
          this.list = []
          this.total = 0
        }
      })
    },
    deleteInventory(id){
      delInventory([{id:id}]).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("删除成功")
          this.getList()
        }
      })
    },
    delAll(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1) 
        return
      }
      delInventory(this.selects).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("删除成功")
          this.getList()
        }
      })
    },
    add(){
      this.$refs.addInventory.init()
    },
    create(row){
      this.$refs.addInventory.init(row)
    },
    handleSelectionChange(val) {
      this.selects = val;
      // let ids = []
      // val.map(v=>{
      //   ids.push(v.id)
      // })
      // this.ids = ids
    },
  }
}
</script>

<style>

</style>

<style scoped lang="scss">
 
.el-select{
  margin-left:0px
}
.app-containers {
  padding: 10px 20px;
  width: 100%;
  margin: 0 auto 0px auto;
  min-height: calc(100vh - 270px);
  border:1px solid #e6e6e6;
  position: relative;
}
</style>
<template>
  <div class="app-containers">
    <div class="top_btns">
      <div class="left_box">
        <el-input size="small" placeholder="请输入存货名称" style="width:34%" v-model="listQuery.name" clearable></el-input>
        <el-button size="small" type="primary" @click="getList"   >
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
      </div>
      <div class="right_box">
        <qzf-button jz="true" @success="inventoryStockIn" size="small">
          <el-icon><SortDown /></el-icon><span  > 初始化入库</span>
        </qzf-button>

        <qzf-import-back @success="getList()" from="存货"></qzf-import-back>
        <qzf-button jz="true" size="small" @success="resetInventory" style="margin-left:10px">重置</qzf-button>
      </div>
    </div>
    <el-table stripe size="small" border :height="contentStyleObj" :data="list">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="name" label="科目名称" min-width="80">
        <template #default="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="inventoryName" label="存货名称" min-width="80">
        <template #default="scope">
          <span>{{ scope.row.inventoryName }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="spec" label="规格型号" min-width="80">
        <template #default="scope">
          <span>{{ scope.row.spec }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="unit" label="计量单位" min-width="80">
        <template #default="scope">
          <span>{{ scope.row.unit }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="count" label="期初数量" min-width="80">
        <template #default="scope">
          <span>{{ scope.row.count }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="amount" label="期初金额" min-width="80">
        <template #default="scope">
          <span>{{ scope.row.amount }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
  </div>
  <inventoryStock @success="getList()" ref="inventoryStockIn" name="初始化入库"></inventoryStock>
</template>

<script>
import {  importList ,delInventoryDetailInit } from "@/api/inventory"
import inventoryStock from "./inventoryStock.vue"
export default {
  name:'inventoryInit',
  data() {
    return {
      list:[],
      listQuery:{
        page:1,
        limit:20,
        name:''
      },
      total:0
    }
  },
  components:{
    inventoryStock,
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(380)
  },
  methods:{
    inventoryStockIn(){
      this.$refs.inventoryStockIn.init()
    },
    getList(){
      importList(this.listQuery).then(res=>{
        if(res.data.msg == "success"){
          this.list = res.data.data.list
          this.total = res.data.data.total
        }else{
          this.list = []
        }
      })
    },
    resetInventory(){
      this.$confirm('确定要重置吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delInventoryDetailInit({}).then(res => {
          if(res.data.msg == "success"){
            this.$qzfMessage('重置成功')
            this.getList()
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-containers {
  padding: 10px 20px;
  width: 100%;
  margin: 0 auto 0px auto;
  min-height: calc(100vh - 270px);
  border:1px solid #e6e6e6;
  position: relative;
}
.top_btns {
  margin-bottom: 36px;
   .left_box{
    float: left;
    width: 30%;
  }
  .right_box {
    float: right;
  }
}
</style>